<script setup lang="ts">
const { getPlayback, playback } = usePlaybackStore()

await callOnce(async () => {
  const response = await getPlayback()
  if (!response?.data?.value) {
    throw createError({
      statusCode: 404,
      message: `🚨 Profile Unavailable`,
      statusMessage: 'Apologies, the profile you are looking for is not available.',
    })
  }
})
</script>

<template>
  <div class="min-h-screen flex flex-col justify-between space-y-6">
    <!-- Header -->
    <div container="padded" grid="~ cols-[1fr_1fr]" class="z-10 gap-4 bg-base py-2" border="b base">
      <div class="flex items-center">
        <ClientOnly>
          <GAvatar
            v-if="playback?.organization?.logo" :src="playback?.organization?.logo"
            :label="playback?.organization?.name"
            avatar="~"
            :alt="playback?.organization?.name"
            rounded="md"
            square="auto"
            :una="{
              avatarImage: 'object-cover w-auto h-40px aspect-auto',
            }"
          />
        </ClientOnly>
      </div>

      <div class="flex items-center justify-end space-x-2">
        <div class="flex flex-col items-end md:mr-1 md:-space-y-2">
          <span class="hidden text-0.7em md:block text-muted">Powered by</span>
          <AppLogo size="md:0.7em" />
        </div>

        <GSeparator orientation="vertical" class="h-7" />

        <SettingsColorMode />
        <GThemeSwitcher />
      </div>
    </div>

    <!-- Body -->
    <main container="padded base" class="max-w-7xl w-auto lg:px-8">
      <slot />
    </main>

    <LazyAppFooter />
  </div>
</template>
